import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/commons";
import Seo from "../components/layout/seo";

const Page = ({ data, pageContext }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  const isHome =
    (story?.content?.page_information ?? [{}])[0]?.page_name === "home";

  return (
    <Layout languageMappings={pageContext?.lm ?? {}}>
      <Seo
        info={story?.content?.page_information[0]}
        lm={pageContext?.lm ?? {}}
        lang={story.lang}
      />
      {/* {!isHome && (
        <div
          style={{
            background:
              "linear-gradient(90.87deg, #A71970 -1.01%, #D72E38 52.32%, #F49235 108.46%)",
          }}
          className="h-5 lg:h-11"
        />
      )} */}
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $images: [String]!) {
    storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(filter: { base: { in: $images } }) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Page;
